import 'bootstrap'
import 'chart.js'
import '../sass/main.scss'
import '@fortawesome/fontawesome-free/css/all.css'

// Page related javascript classes & modules
import './_uiService'
import './_sparkline';
import './_search';
import './_chat';
import './_calendar';
import './_email';
import './_noty';
import './_kanban';
import './_analytics';
import './_sidebar';
import './_map';



