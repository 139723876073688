import mapboxgl from 'mapbox-gl'
import 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import uiService from "./_uiService";
const loadGoogleMapsApi = require('load-google-maps-api')

/* Maps */
$(function () {
    let self = this;

    // Toggle lena sidebar
    $('.sidebar-toggle-item').click(() => {
        setTimeout(() => {
            map.resizeMap();
        }, 1000);
    });

    /* Maps */
    let map = {
        map: null,
        type: null,
        coordinates: [],
        getCoordinates:()=>{

            for(var i=0;i<10;i++){
                map.coordinates.push({
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [-74.0093 + (Math.random() * 0.004) + 0.002, 40.7137 + (Math.random() * 0.004) + 0.002]
                    }
                });
            }
          return map.coordinates;
        },
        initMapboxMap: (is3d, drawCircles, container) => {
            map.type = 'mapbox';
            mapboxgl.accessToken = 'pk.eyJ1IjoiZXJjc2V5cGV0ZXIiLCJhIjoiY2l0OTJ3eW5lMDAydTJvcWRoYTM4aGxuaiJ9.HV6aSQKlvlD1NuCxvJHTnA';
            map.map = new mapboxgl.Map({
                container: container,
                style: is3d ? 'mapbox://styles/mapbox/light-v9' : 'mapbox://styles/mapbox/streets-v9',
                center: [-74.0066, 40.7135],
                zoom: 16,
                pitch: 45,
                bearing: -17.6,
            });



                const self = this;
                map.map.on('load', function () {
                    if (drawCircles) {
                        map.map.addSource('markers', {
                            "type": "geojson",
                            "data": {
                                "type": "FeatureCollection",
                                "features": map.getCoordinates()
                            }
                        });
                        map.map.addLayer({
                            "id": "circles1",
                            "source": "markers",
                            "type": "circle",
                            "paint": {
                                "circle-radius": 10,
                                "circle-color": (Math.floor(Math.random() * 10) + 1) % 2 ?  '#36A2EB' : '#FF6384',
                                "circle-opacity": 1,
                                "circle-stroke-width": 2,
                                "circle-stroke-color":'#fff'
                            }
                        });
                    }
                    if (is3d) {
                        map.add3dLayer();
                    }
                    if(map.coordinates.length){
                        map.map.setCenter(map.coordinates[0].geometry.coordinates);
                    }
                });

        },
        add3dLayer: () => {
            let layers = map.map.getStyle().layers;

            let labelLayerId;
            for (let i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                }
            }
            map.map.addLayer({
                'id': '3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "height"]
                    ],
                    'fill-extrusion-base': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "min_height"]
                    ],
                    'fill-extrusion-opacity': .6
                }
            }, labelLayerId);
        },
        initGoogleMaps: () => {
            let self = this;
            map.type = 'google';
            loadGoogleMapsApi({key: 'AIzaSyDnf80r0S6vKWMWRztQ1a5-b3r6-OV_6Lk'}).then((googleMaps) => {
                map.map = new googleMaps.Map(document.querySelector('#googleMaps'), {
                    center: {
                        lat: 40.7484405,
                        lng: -73.9944191
                    },
                    disableDefaultUI: true,
                    zoom: 12
                })
            }).catch((error) => {
                console.error(error)
            })
        },
        initLeafletMaps: () => {
            let self = this;
            map.type = 'leaflet';
            map.map = L.map('leaflet').setView([51.505, -0.09], 13);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map.map);


        },
        resizeMap: () => {
            if (map.type === 'mapbox') {
                map.map.resize();
            } else if (map.type === 'google') {
                google.maps.event.trigger(map.map, "resize");
            } else if (map.type === 'leaflet') {
                map.map.invalidateSize()
            }
        }
    };

    // Init page specific map
    if ($('#mapboxMap').length)
        map.initMapboxMap(true, true, 'mapboxMap');
    if ($('#mapbox').length)
        map.initMapboxMap(false, false, 'mapbox');
    if ($('#googleMaps').length)
        map.initGoogleMaps();
    if ($('#leaflet').length)
        map.initLeafletMaps();


    $("body").on("keyup", "#map-bearing", function () {
        if (parseInt($(this).val()) > 0)
            map.map.setBearing(parseInt($(this).val()));
    });
    $("body").on("keyup", "#map-pitch", function () {
        if (parseInt($(this).val()) > 0)
            map.map.setPitch(parseInt($(this).val()));
    });
    $("body").on("click", ".mapStyle", function () {
        let elem = $(this);
        const type = $(this).attr('data-type');
        $('.mapStyle').removeClass('active');
        $(this).addClass('active');
        console.log(type);
        if (map.type === 'google') {
            map.map.setOptions({styles: []});
            if (type === 'satellite') {
                map.map.setMapTypeId(google.maps.MapTypeId.HYBRID)
            } else if (type === 'street') {
                map.map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
            } else {
                map.map.setOptions({
                    styles: [
                        {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                        {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                        {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                        {
                            featureType: 'administrative.locality',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#d59563'}]
                        },
                        {
                            featureType: 'poi',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#d59563'}]
                        },
                        {
                            featureType: 'poi.park',
                            elementType: 'geometry',
                            stylers: [{color: '#263c3f'}]
                        },
                        {
                            featureType: 'poi.park',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#6b9a76'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{color: '#38414e'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry.stroke',
                            stylers: [{color: '#212a37'}]
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#9ca5b3'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{color: '#746855'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{color: '#1f2835'}]
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#f3d19c'}]
                        },
                        {
                            featureType: 'transit',
                            elementType: 'geometry',
                            stylers: [{color: '#2f3948'}]
                        },
                        {
                            featureType: 'transit.station',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#d59563'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{color: '#17263c'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{color: '#515c6d'}]
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{color: '#17263c'}]
                        }
                    ]
                })
            }
        } else if (map.type === 'mapbox') {

            if (type === 'street') {
                map.map.setStyle('mapbox://styles/ercseypeter/cj8pv1h9m523m2rpm05cjgx9o');
            } else if (type === 'satellite') {
                map.map.setStyle('mapbox://styles/ercseypeter/cj8pvf7kaa1l62rqmwzduxuo3');
            } else if (type === 'wham') {
                map.map.setStyle('mapbox://styles/ercseypeter/cj8pv01mi8ylm2rs5eqsyhw0t');
            } else if (type === '3d') {
                map.map.removeLayer('3d-buildings');
                map.map.setStyle('mapbox://styles/mapbox/light-v9');
                map.map.add3dLayer();
            }

        }


    });

    if (uiService.isMobile()) {
        setTimeout(()=>{
            map.resizeMap();
        },1000)
    }


});
/* End of Maps */
