import 'jquery-sparkline';

/* Sparkline charts */
$(function () {
    let sparkline = {
        generateRandomData:function (iterator, min, max) {
            let numbers = [];
            for(var i=0; i<iterator;i++){
                numbers.push(Math.floor(Math.random() * max) + min)
            }
            return numbers;
        }
    }

    $("[data-sparkline='line']" ).each(function( index ) {
        $(this).sparkline(sparkline.generateRandomData(10, 1, 15), {
            type: 'line',
            lineColor: '#36a2eb',
            lineWidth: 2,
            width: '100px',
            spotColor: '#ff6384'});
    });

    $("[data-sparkline='bar']" ).each(function( index ) {
        $(this).sparkline(sparkline.generateRandomData(10, 1, 15), {
            type: 'bar',
            barColor: '#36a2eb',
            barWidth: 5,
            barSpacing: 3,
            width: '150px'});
    });

    $("[data-sparkline='pie']" ).each(function( index ) {
        $(this).sparkline(sparkline.generateRandomData(10, 1, 15), {
            type: 'pie',
            sliceColors: ['#36a2eb','#ff6384','#FFCD56','#77dd77'],
            offset:0,
            width: '150px'});
    });

    $("[data-sparkline='discrete']" ).each(function( index ) {
        $(this).sparkline(sparkline.generateRandomData(30, 1, 15), {
            type: 'discrete',
            lineColor: '#36a2eb'});
    });

    $("[data-sparkline='bullet']" ).each(function( index ) {
        $(this).sparkline(sparkline.generateRandomData(10, 1, 15), {
            type: 'bullet',
            targetColor: '#36a2eb',
            width: '150px'});
    });



});

