/* Search */
$(function () {
    $('[data-search-toggle="toggle"]').click(() => {
        $('.lena-search-overlay').toggle();
    });
    $('.lena-search-overlay input').on('keyup', () => {
        let txt = $('.lena-search-overlay input').val().length ? $('.lena-search-overlay input').val() : 'No suggestions';
        $('.lena-suggestions').html(txt);
        if (txt.length > 3) {
            $('.lena-results').fadeIn();
        } else {
            $('.lena-results').fadeOut();
        }
    });

});
/* End of Search */
