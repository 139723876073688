/* Sidebar */
import PerfectScrollbar from 'perfect-scrollbar';
import uiService from './_uiService';

$(function () {
    let self = this;

    const sidebar = {
        resizeTimer: null,
        initSidebar: () => {
            if ($('#lena-sidebar').length)
                new PerfectScrollbar('#lena-sidebar');

            if (uiService.isMobile()) {
                sidebar.toggleSidebar('close');
            }
        },
        toggleSidebar: (direction) => {
            let elem = $('.sidebar, .lena-navbar, [role="main"], [role="map"], [role="mail"]');
            direction === 'open' ? elem.addClass('open') : (direction === 'toggle') ? elem.toggleClass('open') : elem.removeClass('open');
        },
        toggleTheme: (theme) => {
            $('.lena-sidebar').removeClass('lena-light lena-dark').addClass('lena-' + theme);
            if(theme === 'dark'){
                $('.lena-normal-logo').attr('src','assets/img/logo-white.png');
                $('.lena-small-logo').attr('src','assets/img/logo-small-white.png');
            } else{
                $('.lena-normal-logo').attr('src','assets/img/logo.png');
                $('.lena-small-logo').attr('src','assets/img/logo-small.png');
            }
        }

    };
    // Toggle lena sidebar
    $('body').on('click','.sidebar-toggle-item',() => {
        if (!uiService.isMobile()) {
            sidebar.toggleSidebar('toggle');
        }
    });

// Toggle Lena mobile sidebar
    $('[data-mobile-sidebar-toggle]').click(() => {
        if (uiService.isMobile()) {
            $('.lena-mobile-sidebar').toggle();
        }
    });

// Change theme of sidebar
    $('[data-theme-toggle]').click((e) => {
        $('[data-theme-toggle]').removeClass('active');
        $(this).addClass('active');
        sidebar.toggleTheme($(e.currentTarget).attr('data-theme-toggle'));
        window.scrollTo(0,0);
    });

    // Toggle mobile sidebar
    $('.lena-mobile-sidebar-toggle').click((e) => {
        $('#lena-mobile-simple-sidebar').toggle();
    });




// Sidebar arrow toogles
    $('.collapse-panel').on('show.bs.collapse', function () {
        $(this).parent().find('.fa-angle-right').addClass('active');
    });
// Sidebar arrow toogles
    $('.collapse-panel').on('hide.bs.collapse', function () {
        $(this).parent().find('.fa-angle-right').removeClass('active');
    });

// Init Lena sidebar with perfect scroll
    sidebar.initSidebar();

// Toggle emerald sidebar on mobile devices
    $(window).resize(() => {
        clearTimeout(sidebar.resizeTimer);
        sidebar.resizeTimer = setTimeout(() => {
            if (uiService.isMobile()) {
                sidebar.toggleSidebar('close');
            } else {
                sidebar.toggleSidebar('open');
                $('#lena-mobile-simple-sidebar').hide();
            }
        }, 600);
    });
});
/* End of Sidebar */
