/* Analytics */
import {GoogleCharts} from "google-charts";

$(function () {
    let self = this;

    const analytics = {
        geneareteData: () => {
            return [20, 30, 40, 31, 45, 35, 25];
        },
        randomScalingFactor: () => {
            return Math.round(Math.random() * 100);
        },
        drawChart: (domId, type, data, label) => {
            const options = {
                polar: {
                    type: 'polarArea',
                    data: {
                        datasets: [{
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                            ],
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 205, 86)'
                            ],
                            label: 'My dataset' // for legend
                        }],
                        labels: [
                            'Red',
                            'Orange',
                            'Yellow',
                            'Green',
                            'Blue'
                        ]
                    },
                    options: {
                        responsive: true,
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Chart.js Polar Area Chart'
                        }
                    }
                },
                radar: {
                    type: type,
                    data: {
                        labels: label,
                        datasets: [{
                            data: analytics.geneareteData(),
                            label: 'M1',
                            backgroundColor: 'rgb(255, 99, 132)',
                            fill: 1
                        }, {
                            data: analytics.geneareteData(),
                            label: 'D1',
                            backgroundColor: 'rgb(54, 162, 235)',
                            fill: 1
                        }, {
                            data: analytics.geneareteData(),
                            label: 'D2',
                            backgroundColor: 'rgb(255, 205, 86)',
                            fill: 1
                        }, {
                            data: analytics.geneareteData(),
                            label: 'D3',
                            backgroundColor: 'rgb(255, 99, 132)',
                            fill: 1
                        }, {
                            data: analytics.geneareteData(),
                            label: 'D4',
                            backgroundColor: 'rgb(54, 162, 235)',
                            fill: 1
                        }, {
                            data: analytics.geneareteData(),
                            label: 'D5',
                            backgroundColor: 'rgb(255, 99, 132)',
                            fill: 1
                        }]
                    },
                    options: {
                        maintainAspectRatio: true,
                        spanGaps: false,
                        elements: {
                            line: {
                                tension: 0.000001
                            }
                        },
                        plugins: {
                            filler: {
                                propagate: false
                            },
                            'samples-filler-analyser': {
                                target: 'chart-analyser'
                            }
                        },
                        legend: {
                            display: false
                        }
                    }
                },
                bar: {
                    type: "bar",
                    data: {
                        labels: label,
                        datasets: [{
                            data: data,
                            fill: true,
                            backgroundColor: ['#d7dce0', '#2ea0ee', '#d7dce0', '#d7dce0', '#2ea0ee', '#d7dce0', '#d7dce0']
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }]
                        }
                    }
                },
                stacked: {
                    type: "bar",
                    labels: label,
                    data: {
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                        datasets: [{
                            label: 'Dataset 1',
                            backgroundColor: 'rgb(255, 99, 132)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }, {
                            label: 'Dataset 2',
                            backgroundColor: 'rgb(54, 162, 235)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }, {
                            label: 'Dataset 3',
                            backgroundColor: 'rgb(255, 205, 86)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                },
                                stacked: true
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                },
                                stacked: true
                            }]
                        }
                    }
                },
                stackedGeo: {
                    type: "bar",
                    labels: label,
                    data: {
                        labels: ['NY SE', 'LD SE', 'TY SE', 'BP SE'],
                        datasets: [{
                            label: 'Dataset 1',
                            backgroundColor: 'rgb(255, 99, 132)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }, {
                            label: 'Dataset 2',
                            backgroundColor: 'rgb(54, 162, 235)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }, {
                            label: 'Dataset 3',
                            backgroundColor: 'rgb(255, 205, 86)',
                            data: [
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor(),
                                analytics.randomScalingFactor()
                            ]
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                },
                                stacked: true
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                },
                                stacked: true
                            }]
                        }
                    }
                },
                pie: {
                    type: type,
                    data: {
                        labels: label,
                        datasets: [{
                            label: "Mediums",
                            data: data,
                            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"]
                        }]
                    },
                    options: {
                        legend: {
                            position: 'bottom'
                        },
                    }
                },
                doughnut: {
                    type: type,
                    data: {
                        labels: label,
                        datasets: [{
                            label: "Mediums",
                            data: data,
                            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"]
                        }]
                    },
                    options: {
                        legend: {
                            position: 'bottom'
                        },
                    }
                },
                line: {
                    type: "line",
                    data: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                        datasets: [{
                            data: [20, 30, 40, 31, 45, 35, 25],
                            fill: true,
                            backgroundColor: 'rgba(54, 162, 235)',
                            borderColor: 'rgb(54, 162, 235)'
                        }, {
                            data: [40, 50, 60, 45, 54, 45, 30],
                            fill: true,
                            backgroundColor: 'rgb(255, 205, 86)',
                            borderColor: 'rgb(255, 205, 86)'
                        }, {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            fill: true,
                            backgroundColor: 'rgba(255, 99, 132)',
                            borderColor: 'rgb(255, 99, 132)'
                        }]
                    },
                    options: {
                        showLines: true,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }]
                        }
                    }
                },
                thin: {
                    type: "line",
                    data: {
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                        datasets: [{
                            data: [20, 30, 40, 31, 45, 35, 25],
                            fill: false,
                            borderColor: 'rgb(54, 162, 235)'
                        }, {
                            data: [40, 50, 60, 45, 54, 45, 30],
                            fill: false,
                            borderColor: 'rgb(255, 205, 86)'
                        }, {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)'
                        }]
                    },
                    options: {
                        showLines: true,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    display: false
                                }
                            }]
                        }
                    }
                }
            }
            const ctx = document.getElementById(domId).getContext('2d');
            new Chart(ctx, options[type]);
        },
        initGoogleCharts() {
            GoogleCharts.load(this.drawGoogleCharts, {'mapsApiKey': 'AIzaSyDnf80r0S6vKWMWRztQ1a5-b3r6-OV_6Lk'});

        },
        drawGoogleCharts() {
            if ($('#googleChart7').length)
                analytics.drawGoogleChart([
                    ['State', 'Spend'],
                    ['ID', {v: 120000, f: '$120,000'}],
                    ['CO', {v: 567135, f: '$567,135'}],
                    ['FL', {v: 220000, f: '$220,000'}],
                    ['NY', {v: 1120000, f: '$1,120,000'}],
                    ['CA', {v: 5120000, f: '$5,120,000'}],
                    ['AK', {v: 101000, f: '$101,000'}],
                    ['AZ', {v: 311030, f: '$311,030'}]
                ], 'googleChart7', {
                    region: 'US',
                    colors: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                    resolution: 'provinces',
                    is3D: true,
                    responsive: true,
                    legend: {
                        numberFormat: '$###,###'
                    }
                }, 'geo');
            if ($('#googleChart6').length)
                analytics.drawGoogleChart([
                    ['State', 'Spend'],
                    ['ID', {v: 120000, f: '$120,000'}],
                    ['CO', {v: 567135, f: '$567,135'}],
                    ['FL', {v: 220000, f: '$220,000'}],
                    ['NY', {v: 1120000, f: '$1,120,000'}],
                    ['CA', {v: 5120000, f: '$5,120,000'}],
                    ['AK', {v: 101000, f: '$101,000'}],
                    ['AZ', {v: 311030, f: '$311,030'}]
                ], 'googleChart6', {
                    region: 'US',
                    colors: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                    resolution: 'provinces',
                    is3D: true,
                    legend: {
                        numberFormat: '$###,###'
                    }
                }, 'geo');
            if ($('#googleChart5').length)
                analytics.drawGoogleChart([
                    ['Age', 'Weight'],
                    [8, 12],
                    [4, 5.5],
                    [11, 14],
                    [4, 5],
                    [3, 3.5],
                    [6.5, 7]
                ], 'googleChart5', {
                    legend: {
                        position: 'bottom',
                        textStyle: {
                            color: 'black',
                            fontSize: 13
                        }
                    },
                    bar: {groupWidth: '25%'},
                    colors: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                    chartArea: {left: 0, top: 0, width: '100%', height: '80%'},
                    axisTitlesPosition: 'none',
                    hAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                    vAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                }, 'scatter');
            if ($('#googleChart3').length)
                analytics.drawGoogleChart([
                    ['Year', 'Sales', 'Expenses'],
                    ['2004', 1000, 400],
                    ['2005', 1170, 460],
                    ['2006', 660, 1120],
                    ['2007', 1030, 540]
                ], 'googleChart3', {
                    legend: {
                        position: 'bottom',
                        textStyle: {
                            color: 'black',
                            fontSize: 13
                        }
                    },
                    bar: {groupWidth: '25%'},
                    colors: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                    isStacked: true,
                    chartArea: {left: 0, top: 0, width: '100%', height: '80%'},
                    axisTitlesPosition: 'none',
                    hAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                    vAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                }, 'line');
            if ($('#googleChart2').length)
                analytics.drawGoogleChart([
                    ['Chart 1', 'Organic visitors', 'Direct visitors', 'Referral visitors'],
                    ['Chart 1', 22, 10, 68]
                ], 'googleChart2', {
                    legend: {
                        position: 'bottom',
                        textStyle: {
                            color: 'black',
                            fontSize: 13
                        }
                    },
                    bar: {groupWidth: '25%'},
                    colors: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                    isStacked: true,
                    chartArea: {left: 0, top: 0, width: '100%', height: '80%'},
                    axisTitlesPosition: 'none',
                    hAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                    vAxis: {textPosition: 'none', gridlines: {color: 'transparent'}, baselineColor: 'transparent'},
                }, 'bar');
            if ($('#googleChart1').length)
                analytics.drawGoogleChart([
                    ['Chart thing', 'Chart amount'],
                    ['Organic visitors', 60],
                    ['Direct visitors', 22],
                    ['Referral visitors', 18]
                ], 'googleChart1', {
                    pieHole: 0.8,
                    pieSliceTextStyle: {
                        color: 'black',
                    },
                    slices: {
                        0: {color: 'rgb(255, 99, 132)'},
                        1: {color: 'rgb(54, 162, 235)'},
                        2: {color: 'rgb(255, 205, 86)'}

                    },
                    legend: {
                        position: 'bottom',
                        textStyle: {
                            color: 'black',
                            fontSize: 13
                        }
                    },
                    title: 'Chart 1',
                    titleTextStyle: {
                        color: 'black',
                        fontSize: 13
                    },
                    chartArea: {left: 0, top: 0, width: '100%', height: '80%'},
                    pieSliceText: 'none'
                }, 'pie');
            if ($('#googleChart4').length)
                analytics.drawGoogleChart([
                    ['Chart thing', 'Chart amount'],
                    ['Organic visitors', 60],
                    ['Direct visitors', 22],
                    ['Referral visitors', 18]
                ], 'googleChart4', {
                    pieHole: 1,
                    pieSliceTextStyle: {
                        color: 'black',
                    },
                    slices: {
                        0: {color: 'rgb(255, 99, 132)'},
                        1: {color: 'rgb(54, 162, 235)'},
                        2: {color: 'rgb(255, 205, 86)'}

                    },
                    legend: {
                        position: 'bottom',
                        textStyle: {
                            color: 'black',
                            fontSize: 13
                        }
                    },
                    title: 'Chart 1',
                    titleTextStyle: {
                        color: 'black',
                        fontSize: 13
                    },
                    chartArea: {left: 0, top: 0, width: '100%', height: '80%'},
                    pieSliceText: 'none'
                }, 'pie');
        },
        drawGoogleChart(datas, elementId, options, type) {
            const data = GoogleCharts.api.visualization.arrayToDataTable(datas);
            let chart;
            if (type === 'pie') {
                chart = new GoogleCharts.api.visualization.PieChart(document.getElementById(elementId));
            }
            else if (type === 'bar') {
                chart = new GoogleCharts.api.visualization.BarChart(document.getElementById(elementId));
            }
            else if (type === 'line') {
                chart = new GoogleCharts.api.visualization.LineChart(document.getElementById(elementId));
            }
            else if (type === 'scatter') {
                chart = new GoogleCharts.api.visualization.ScatterChart(document.getElementById(elementId));
            }
            else if (type === 'geo') {
                chart = new GoogleCharts.api.visualization.GeoChart(document.getElementById(elementId));
            }

            chart.draw(data, options);
        },

    };


    analytics.initGoogleCharts();

    if ($('#lena-stock-chart').length)
        analytics.drawChart('lena-stock-chart', 'thin', [300, 50, 100], ["Organic", "Direct", "Referral"]);
    if ($('#lineChart').length)
        analytics.drawChart('lineChart', 'line', [300, 50, 100], ["Organic", "Direct", "Referral"]);
    if ($('#pieChart').length)
        analytics.drawChart('pieChart', 'doughnut', [300, 50, 100], ["Organic", "Direct", "Referral"]);
    if ($('#donughtChart').length)
        analytics.drawChart('donughtChart', 'pie', [300, 50, 100], ["Organic", "Direct", "Referral"]);
    if ($('#pieChartMedium').length)
        analytics.drawChart('pieChartMedium', 'doughnut', [400, 50, 160, 30], ["Facebook", "Google", "Instagram", "Referral"]);
    if ($('#pieChartVelocity').length)
        analytics.drawChart('pieChartVelocity', 'doughnut', [10, 8, 6, 4], ["Anna", "Erica", "Susan", "Jane"]);
    if ($('#barChart').length)
        analytics.drawChart('barChart', 'bar', [65, 130, 80, 81, 110, 55, 40], ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']);
    if ($('#radarChart').length)
        analytics.drawChart('radarChart', 'radar', [65, 130, 80, 81, 110, 55, 40], ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']);
    if ($('#polarChart').length)
        analytics.drawChart('polarChart', 'polar');
    if ($('#stackedStocks').length)
        analytics.drawChart('stackedStocks', 'stacked', [[65, 78, 80, 81, 110, 55, 40],[80, 130, 80, 81, 110, 55, 40]], [['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']]);
    if ($('#locationStocks').length)
        analytics.drawChart('locationStocks', 'stackedGeo', [[65, 78, 80, 81],[80, 130, 80, 81]], [['NY SE', 'LD SE', 'TY SE', 'BP SE'], ['NY SE', 'LD SE', 'TY SE', 'BP SE']]);
    if ($('#locationStocks1').length)
        analytics.drawChart('locationStocks1', 'stackedGeo', [[65, 78, 80, 81],[80, 130, 80, 81]], [['NY SE', 'LD SE', 'TY SE', 'BP SE'], ['NY SE', 'LD SE', 'TY SE', 'BP SE']]);

    $(window).resize(function(){
        analytics.initGoogleCharts();
    });

    $('[data-progress]').each(function(){
        $(this).css('width', $(this).data('progress') + '%');
    });


});
/* End of Analytics */
