/* Sortable - Kanban */
import Sortable from "sortablejs";

$(function () {
    let sortable = {
        makeSortable: (elementId) => {
            new Sortable(document.getElementById(elementId), {
                animation: 150,
                group: 'shared'
            });
        }
    };
    // Initializing kanban columns
    for (let i = 1; i < 5; i++) {
        if ($('#lena-kanban-row-' + i).length)
            sortable.makeSortable('lena-kanban-row-' + i);
    }
    $('.lena-task-block .fa-check-circle').on('click', function () {
        $(this).parent().parent().parent().fadeOut();
    });
});
/* End of Sortable - Kanban */
