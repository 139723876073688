/* Email */
$(function () {

    $('body .lena-email-envelopes').on('click', 'li', () => {
        $('.lena-detailed-email, .lena-emails').toggle();
    });
    $('body').on('click', '.lena-toggle-mails', () => {
        $('.lena-detailed-email, .lena-emails').toggle();
    });
});
/* End of Email*/
