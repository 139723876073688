import 'noty/src/noty.scss'
import 'noty/src/themes/nest.scss'
import Noty from 'noty'

/* Noty */
$(function () {
    let notificationService = {
        dropNoty: (type, text, timeout) => {
            new Noty({
                type: type,
                layout: 'topRight',
                theme: 'nest',
                text: text,
                timeout: timeout
            }).show();
        }
    };
    if(window.location.href.indexOf('landing') === -1){
        notificationService.dropNoty('info', 'Welcome to Lena Dashboard Framework!', 4000);
    }

});
/* End of Noty */
